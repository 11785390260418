import { Layout } from "antd";
import { Outlet, useNavigate } from "react-router-dom";
import { lazy, Suspense, useEffect } from "react";
import { getAuthToken } from "../../helper/authentication";
import HeaderContent from "./HeaderContent";
import Sidebar from "./Sidebar";
const Loader = lazy(
  () => import("pages/authentication/components/common/Loader")
);
const { Content } = Layout;
const PagesLayout = () => {
  const navigate = useNavigate();

  useEffect(() => {
    if (!getAuthToken()) {
      navigate("/login");
    }
  }, [navigate]);

  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Sidebar />
      <Layout style={{ minHeight: "100vh" }}>
        <HeaderContent />
        <Suspense fallback={<Loader />}>
          <Content className="whiteBox shadow main-content">
            <Outlet />
          </Content>
        </Suspense>
      </Layout>
    </Layout>
  );
};

export default PagesLayout;
