// =================== import packages ==================
import axios, { AxiosRequestConfig } from "axios";
// import { Store } from '@reduxjs/toolkit';
// ======================================================
// import { removeToast, setToast } from 'redux/slices/toastSlice';
// import { setLogoutData } from "redux/slices/authSlice";
import { REACT_APP_API_URL } from "config";

export const Axios = axios.create({ baseURL: REACT_APP_API_URL });

// export const setupAxios = (store: Store) => {
export const setupAxios = () => {
  // logic of set token in header
  Axios.interceptors.request.use((request: AxiosRequestConfig) => {
    const authToken = localStorage.getItem("access_token");

    if (request.headers !== undefined && authToken) {
      request.headers.Authorization = `JWT ${authToken}`;
    }

    return request;
  });
  // for toast message setup
  Axios.interceptors.response.use(
    (res) => {
      const { user, access_token } = res.data?.data;
      if (access_token) {
        localStorage.setItem("access_token", access_token);
      }
      if (user) {
        localStorage.setItem("user", JSON.stringify(user));
      }
      return res.data;
    },
    (e) => {
      if (e.response.status === 401) {
        // Logout Code
        // const storeData = store.getState();
        // if (storeData.token !== null) {
        // store.dispatch(setLogoutData());
        // }
      }
      if (
        e.response.status === 400 ||
        e.response.status === 500 ||
        e.response.status === 401
      ) {
        const { toast } = e.response.data;
        if (toast) {
          // ----------- set api response toast -----------
          // const toastId = new Date().getTime();
          // store.dispatch(
          //   setToast({
          //     message: e.response.data.message,
          //     type: e.response.data.responseType,
          //     id: toastId,
          //   })
          // );
          // setTimeout(() => {
          //   store.dispatch(removeToast({ id: toastId }));
          // }, 2000);
        }
      }
      throw e.response.data;
    }
  );
};

export default axios;
