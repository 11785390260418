import { FormInstance, notification } from "antd";

export const AUTH_TOKEN = "getAuthToken";

export const toastSuccess = (message: string) => {
  notification.success({ message: message });
};

export const toastError = (message: string) => {
  notification.error({ message: message });
};

export const isValidArray = (data: any) =>
  data && Array.isArray(data) && data.length > 0;

export const isValidObject = (data: {}) =>
  typeof data === "object" && data ? Object.keys(data).length > 0 : false;

export const processTableData = (data: any) =>
  isValidArray(data)
    ? data?.map((d: any) => ({
        ...d,
        key: d?.id,
      }))
    : [];

export const resetForm = (form: FormInstance<any>) => form.resetFields();
