// import { Image } from "antd";

import { Header } from "antd/es/layout/layout";

const Page404 = () => {
  return (
    <>
      <Header
        style={{
          background: "#ffffff00",
          marginLeft: "600px",
          fontWeight: "700",
          fontSize: "larger",
        }}
      >
        Sorry !! Page That You are Lookingfor is not available
      </Header>

      <img
        width={500}
        height={500}
        style={{ marginLeft: "500px", padding: "100px" }}
        src="https://wpklik.com/wp-content/uploads/2019/03/A-404-Page-Best-Practices-and-Design-Inspiration.jpg"
        alt=""
      />
    </>
  );
};
export default Page404;
