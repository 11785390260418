export const storageKey = "access_token";

export const checkLogin = () => !!localStorage.getItem(storageKey);

export const getAuthToken = () => localStorage.getItem(storageKey);

export const setAuthToken = (token: string = "") =>
  localStorage.setItem(storageKey, token);

export const setToken = (key: string, token: string = "") =>
  localStorage.setItem(key, token);

export const removeAuthToken = () => localStorage.removeItem(storageKey);
