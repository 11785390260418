import {
  AppstoreOutlined,
  PlusCircleOutlined,
  MinusCircleOutlined,
  UnorderedListOutlined,
  PicLeftOutlined,
  BorderOutlined,
  PieChartOutlined,
  MenuOutlined,
  TrophyOutlined,
  ClockCircleOutlined,
  CopyOutlined,
} from "@ant-design/icons";
import { Layout, Menu } from "antd";
import { ItemType, MenuItemType } from "antd/es/menu/hooks/useItems";
import { Link, useNavigate } from "react-router-dom";

const { Sider } = Layout;

const menuItems: ItemType<MenuItemType>[] = [
  {
    label: "Dashboard",
    icon: <AppstoreOutlined />,
    key: "/",
  },
  {
    label: "Pages",
    icon: <BorderOutlined />,
    key: "/pages",
  },
  {
    label: "Authors",
    icon: <PieChartOutlined />,
    key: "/authors",
  },
  {
    label: "Blog Categories",
    icon: <UnorderedListOutlined />,
    key: "/blog-category",
  },
  {
    label: "Blogs",
    icon: <PicLeftOutlined />,
    key: "/blogs",
  },
  {
    key: "parent-products",
    icon: <PlusCircleOutlined />,
    label: "Models",
    children: [
      {
        label: "Platform",
        icon: <MinusCircleOutlined />,
        key: "/segment/platform",
      },
      {
        label: "Framework",
        icon: <MinusCircleOutlined />,
        key: "/segment/framework",
      },
      {
        label: "Industries",
        icon: <MinusCircleOutlined />,
        key: "/segment/industry",
      },
      {
        label: "Language",
        icon: <MinusCircleOutlined />,
        key: "/segment/language",
      },
      {
        label: "CMS",
        icon: <MinusCircleOutlined />,
        key: "/segment/cms",
      },
      {
        label: "Service",
        icon: <MinusCircleOutlined />,
        key: "/segment/service",
      },
      {
        label: "Solution",
        icon: <MinusCircleOutlined />,
        key: "/segment/solution",
      },
      {
        label: "Functional",
        icon: <MinusCircleOutlined />,
        key: "/segment/functional",
      },
      {
        label: "NonFunction",
        icon: <MinusCircleOutlined />,
        key: "/segment/non-functional",
      },
      {
        label: "Types",
        icon: <MinusCircleOutlined />,
        key: "/segment/type",
      },
      {
        label: "E-commerce",
        icon: <MinusCircleOutlined />,
        key: "/segment/ecommerce",
      },
      {
        label: "ManagedService",
        icon: <MinusCircleOutlined />,
        key: "/segment/managed-service",
      },
      {
        label: "Engine",
        icon: <MinusCircleOutlined />,
        key: "/segment/engine",
      },
      {
        label: "Country",
        icon: <MinusCircleOutlined />,
        key: "/segment/country",
      },
      {
        label: "State",
        icon: <MinusCircleOutlined />,
        key: "/segment/state",
      },
      {
        label: "City",
        icon: <MinusCircleOutlined />,
        key: "/segment/city",
      },
      {
        label: "Other",
        icon: <MinusCircleOutlined />,
        key: "/segment/other",
      },
    ],
  },
  {
    label: "Company",
    icon: <UnorderedListOutlined />,
    key: "/company",
  },
  {
    label: "Awards",
    icon: <TrophyOutlined />,
    key: "/awards",
  },
  {
    label: "Timezones",
    icon: <ClockCircleOutlined />,
    key: "/timezones",
  },
  {
    label: "Spoken Language",
    icon: <CopyOutlined />,
    key: "/spokenLanguages",
  },
];

const Sidebar = () => {
  const navigate = useNavigate();
  const { pathname } = window.location;

  const getKeys = () => {
    let openKey = "";
    let selectKey = pathname;

    menuItems.forEach((d) => {
      const key = d?.key as string;
      if (pathname.endsWith(key)) {
        selectKey = key;
      }
    });

    return {
      openKeys: openKey ? [openKey] : [],
      selectedKeys: selectKey ? [selectKey] : [],
    };
  };

  const onClick = (e: { key: string }) => {
    navigate(e.key);
  };

  const { openKeys, selectedKeys } = getKeys();

  return (
    <Sider
      trigger={<MenuOutlined className="trigger" />}
      width={300}
      collapsible
      className="sidePanel"
    >
      <div className="sidebar-logo">
        <Link to="/" className="dashboard-title-main">
          <h1 className="dashboard-title">FirmVisor</h1>{" "}
        </Link>
      </div>
      <div className="sidePanelContent">
        <Menu
          defaultOpenKeys={openKeys}
          defaultSelectedKeys={selectedKeys}
          items={menuItems}
          mode="inline"
          className="sideMenu"
          onClick={onClick}
        />
      </div>
    </Sider>
  );
};

export default Sidebar;
