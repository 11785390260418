import { Col, Layout, Row, Typography } from "antd";
import { lazy, Suspense } from "react";
import { Outlet } from "react-router-dom";
const Loader = lazy(
  () => import("pages/authentication/components/common/Loader")
);
const { Content } = Layout;
const { Title } = Typography;
const SideContent = () => {
  return (
    <Content
      style={{
        padding: "150px 30px 30px",
        width: "100%",
        maxWidth: "400px",
        margin: "0 auto",
      }}
      className="sideContent"
    >
      <div style={{ width: "100%" }}>
        LOGO
        <div className="space40"></div>
        <Title level={3}>
          Welcome to<span className="login-title-span">FirmVisor</span>
        </Title>
      </div>
    </Content>
  );
};

const AuthLayout = () => {
  return (
    <Suspense fallback={<Loader />}>
      <Layout>
        <Row>
          <Col
            xs={{ span: 0, order: 2 }}
            sm={{ span: 0, order: 2 }}
            md={{ span: 11, order: 1 }}
            lg={{ span: 12, order: 1 }}
            style={{
              minHeight: "100vh",
            }}
          >
            <SideContent />
          </Col>
          <Col
            xs={{ span: 24, order: 1 }}
            sm={{ span: 24, order: 1 }}
            md={{ span: 13, order: 2 }}
            lg={{ span: 12, order: 2 }}
            style={{ background: "#FFF", minHeight: "100vh" }}
          >
            <Outlet />
          </Col>
        </Row>
      </Layout>
    </Suspense>
  );
};

export default AuthLayout;
