import { lazy } from "react";

interface RoutingType {
  path: string;
  element: React.LazyExoticComponent<() => JSX.Element>;
  isPrivate?: boolean;
  isPublic?: boolean;
}

export const publicRoutes: RoutingType[] = [
  {
    path: "login",
    element: lazy(() => import("../pages/authentication/Login")),
    isPublic: true,
  },
];

export const privateRoutes: RoutingType[] = [
  {
    path: "/",
    element: lazy(() => import("../pages/dashboard/Dashboard")),
    isPrivate: false,
  },
  {
    path: "/segment/:name",
    element: lazy(() => import("../pages/segment/index")),
    isPrivate: false,
  },
  {
    path: "/pages",
    element: lazy(() => import("../pages/page")),
    isPrivate: false,
  },
  {
    path: "/page/add",
    element: lazy(() => import("../pages/page/PageForm")),
    isPrivate: false,
  },
  {
    path: "/blog-category",
    element: lazy(() => import("../pages/blogCategory")),
    isPrivate: false,
  },
  {
    path: "/company",
    element: lazy(() => import("../pages/company")),
    isPrivate: false,
  },
  {
    path: "/company/add",
    element: lazy(() => import("../pages/company/components")),
    isPrivate: false,
  },
  {
    path: "/company/edit/:id",
    element: lazy(() => import("../pages/company/components")),
    isPrivate: false,
  },
  {
    path: "/page/edit/:id",
    element: lazy(() => import("../pages/page/PageForm")),
    isPrivate: false,
  },
  {
    path: "/authors",
    element: lazy(() => import("../pages/author")),
    isPrivate: false,
  },
  {
    path: "/author/add",
    element: lazy(() => import("../pages/author/AuthorForm")),
    isPrivate: false,
  },
  {
    path: "/author/edit/:id",
    element: lazy(() => import("../pages/author/AuthorForm")),
    isPrivate: false,
  },
  {
    path: "/blogs",
    element: lazy(() => import("../pages/blogs")),
    isPrivate: false,
  },
  {
    path: "/blog/add",
    element: lazy(() => import("../pages/blogs/BlogForm")),
    isPrivate: false,
  },
  {
    path: "/blog/edit/:id",
    element: lazy(() => import("../pages/blogs/BlogForm")),
    isPrivate: false,
  },
  {
    path: "/awards",
    element: lazy(() => import("../pages/awards/index")),
    isPrivate: false,
  },
  {
    path: "/timezones",
    element: lazy(() => import("../pages/timezones/index")),
    isPrivate: false,
  },
  {
    path: "/spokenLanguages",
    element: lazy(() => import("../pages/spokenLanguages/index")),
    isPrivate: false,
  },
];
