import { BrowserRouter, Routes, Route } from "react-router-dom";
import { publicRoutes, privateRoutes } from "./routes";
import Layout from "../components/layout";
import Page404 from "../components/layout/Page404";
import ScrollToTop from "components/common/ScrollToTop";
import AuthLayout from "components/layout/AuthLayout";

export const Router = () => {
  return (
    <BrowserRouter>
      <ScrollToTop>
        <Routes>
          <Route element={<AuthLayout />}>
            {publicRoutes.map((route) => (
              <Route
                key={route.path}
                path={route.path}
                element={<route.element />}
              />
            ))}
          </Route>
          <Route element={<Layout />}>
            {privateRoutes.map((route) => (
              <Route
                key={route.path}
                path={route.path}
                element={<route.element />}
              />
            ))}
          </Route>
          <Route path="*" element={<Page404 />}></Route>
        </Routes>
      </ScrollToTop>
    </BrowserRouter>
  );
};
