import { Avatar, Menu, Dropdown } from "antd";
import { UserOutlined, LogoutOutlined } from "@ant-design/icons";
import { removeAuthToken } from "helper/authentication";
import { toastSuccess } from "modules/utils";
import { useNavigate } from "react-router-dom";

const HeaderContent = () => {
  const navigate = useNavigate();
  const profileDropdown = (
    <div
      className="profileDropdown whiteBox shadow"
      style={{ minWidth: "200px" }}
    >
      <div className="pad15">
        <Avatar
          size="large"
          className="last"
          icon={<UserOutlined />}
          style={{ float: "left" }}
        />
        <div className="info">
          <p className="strong">Admin</p>
          <p>superadmin@firmvisor.com</p>
        </div>
      </div>
      <div className="line"></div>
      <Menu className="logout__btn">
        <Menu.Item
          icon={<LogoutOutlined />}
          // key={`${uniqueId()}`}
          onClick={() => {
            removeAuthToken();
            toastSuccess("User Logout Successfully!");
            navigate("/login");
          }}
        >
          logout
        </Menu.Item>
      </Menu>
    </div>
  );
  return (
    <div className="headerIcon">
      <Dropdown
        overlay={profileDropdown}
        trigger={["click"]}
        placement="bottomRight"
      >
        <Avatar className="last" icon={<UserOutlined />} />
      </Dropdown>
    </div>
  );
};

export default HeaderContent;
